<template>
  <v-container
    fluid
    style="max-width: 800px"
  >
    <v-card>
      <v-img
        :src="require('../assets/main_logo.jpeg')"
        class="align-center"
        cover
        max-height="270px"
        style="color: #952175"
      >
        <v-card-title>
          <v-avatar
            size="96px"
          >
            <img
              :src="require('../assets/logo.png')"
              alt="Avatar"
              height="68"
            >
          </v-avatar>
          <span class="text-h4 font-weight-light"><b>Profi-Time</b></span>
        </v-card-title>
        <v-card-subtitle class="text-h6 font-weight-medium mb-3" style="margin-left: 5%">
          <div class="mb-2">Электронная запись</div>
          <div>для Вас и Ваших клиентов</div>
        </v-card-subtitle>
        <v-card-actions>
          <a href="https://lk.profi-time.com" style="margin-left: 7%;">
            <button class="button-33" role="button">Начать пользоваться</button>
          </a>
        </v-card-actions>
      </v-img>
    </v-card>

    <v-row class="text-center mt-10">
      <v-col class="mb-4">
        <h5 class="display-2 mb-3">
          Profi-Time - это
        </h5>
        <h6 class="display-1 mb-3">
          бесплатный инструмент для составления расписаний и онлайн-записи
        </h6>
      </v-col>
    </v-row>
    <v-timeline>
      <v-timeline-item
        v-for="(info, i) in information"
        :key="i"
        :color="info.color"
        small
      >
        <span slot="opposite" v-if="info.link">
          <v-img
            :aspect-ratio="16/9"
            :src="info.link"
          ></v-img>
        </span>
        <div class="py-4">
          <h2 :class="`headline font-weight-light mb-4 ${info.color}--text`">
            {{ info.topic }}
          </h2>
          <div>
            {{ info.description }}
          </div>
        </div>
      </v-timeline-item>
    </v-timeline>

    <v-row text-center>
      <v-col class="mb-4">
        <v-btn
          rounded
          text
          color="#ffffff"
          style="background: #4caf50; margin-left: 5%"
          :href="link"
        >
          Присоединиться!
        </v-btn>
      </v-col>
    </v-row>

    <v-row class="text-center mt-10">
      <v-col class="mb-6">
        <h4 class="display-1 mb-3">
          Оцените преимущества Profi-Time:
        </h4>
      </v-col>
    </v-row>

    <v-item-group>
      <v-container>
        <v-row>
          <v-col
            v-for="(card, n) in cards"
            :key="n"
            cols="12"
            md="4"
          >
            <v-item v-slot="{}">
              <v-card
                :color="card.color"
                class="mx-auto"
                dark
                height="230"
              >
                <v-scroll-y-transition>
                  <div>
                    <v-card-title style="color: white">
                      <v-icon
                        large
                        left
                      >
                        {{ card.icon }}
                      </v-icon>
                      <span class="font-weight-light">{{ card.topic }}</span>
                    </v-card-title>

                    <v-card-text class="text-h6 font-weight-light" style="color: white">
                      {{ card.description }}
                    </v-card-text>
                  </div>
                </v-scroll-y-transition>
              </v-card>
            </v-item>
          </v-col>
        </v-row>
      </v-container>
    </v-item-group>

    <v-row class="text-center mt-10">
      <v-col class="mb-0">
        <h4 class="display-1 mb-0">
          Все еще раздумываете?
        </h4>
      </v-col>
    </v-row>
    <v-row class="text-center mt-2">
      <v-col class="mb-6">
        <h4 class="display-1 mb-3">
          Система абсолютно бесплатна!
        </h4>
      </v-col>
    </v-row>

<!--    <v-item-group>-->
<!--      <v-container>-->
<!--        <v-row>-->
<!--          <v-col-->
<!--            v-for="(price, n) in prices"-->
<!--            :key="n"-->
<!--            cols="12"-->
<!--            md="3"-->
<!--            sm="3"-->
<!--            xs="3"-->
<!--          >-->
<!--            <v-item v-slot="{}">-->
<!--              <v-card-->
<!--                color="primary"-->
<!--                class="mx-auto"-->
<!--                dark-->
<!--                height="160"-->
<!--                max-width="160"-->
<!--              >-->
<!--                <v-scroll-y-transition>-->
<!--                  <div>-->
<!--                    <v-card-title style="color: white">-->
<!--                      <span class="font-weight-medium">{{ price.topic }}</span>-->
<!--                    </v-card-title>-->

<!--                    <v-card-text class="text-h6 font-weight-light" style="color: white">-->
<!--                      <span><h2>{{ price.description }}</h2> рублей</span><br>-->
<!--                      <span><h5>{{ price.footer }}</h5></span>-->
<!--                    </v-card-text>-->
<!--                  </div>-->
<!--                </v-scroll-y-transition>-->
<!--              </v-card>-->
<!--            </v-item>-->
<!--          </v-col>-->
<!--        </v-row>-->
<!--      </v-container>-->
<!--    </v-item-group>-->
<!--    <v-row class="mt-2">-->
<!--      <v-col class="mb-0 text-center">-->
<!--        <h5 class="display-1 mb-3">-->
<!--          Подписка одна! Скидка разная! Отличается лишь период использования!-->
<!--        </h5>-->
<!--      </v-col>-->
<!--    </v-row>-->
    <v-row class="mt-2">
      <v-col class="mb-6">
        <h5 class="display-0 mb-3">
          Расширьте возможности
          подписавшись на телеграм-канал <a href='https://t.me/crm_profitime'>@crm_profitime</a>
          - там выкладываются актуальные новости и возможности
        </h5>
        <h5 class="display-0 mb-3">
          Работайте с командой до 10 сотрудников. Дополнительных ограничений не предусмотрено!
          Если Вам требуется организовать работу большего количества сотрудников - обратитесь с запросом по
          <a href='mailto:lexapin.cheb@gmail.com?subject=Нужно больше сотрудников&body=Message'>почте</a>
        </h5>
      </v-col>
    </v-row>

    <v-row class="text-center mt-10">
      <v-col class="mb-6">
        <h4 class="display-1 mb-3">
          Добро пожаловать!
        </h4>
      </v-col>
    </v-row>

    <v-row text-center>
      <v-col class="mb-4 text-center">
        <a href="https://lk.profi-time.com">
          <button class="button-85" role="button">Присоединиться!</button>
        </a>
      </v-col>
    </v-row>

    <v-footer
      dark
      padless
      class="mt-12"
    >
      <v-card
        flat
        tile
        class="indigo lighten-1 white--text text-center"
        width="100%"
      >
        <v-card-text>
          <v-row class="ml-10 mr-10">
            <v-col
              v-for="icon in icons"
              :key="icon.iconpath"
              justify="center"
            >
              <v-tooltip
                bottom
                class="mx-4 white--text"
              >
                <template v-slot:activator="{ on, attrs }">
                  <a :href="icon.href" class="shrink mr-2">
                    <v-img
                      :src="icon.iconpath"
                      transition="scale-transition"
                      max-width="30"
                      class="mx-auto"
                      v-bind="attrs"
                      v-on="on"
                    />
                  </a>
                </template>
                <span>{{ icon.description }}</span>
              </v-tooltip>
            </v-col>
          </v-row>
        </v-card-text>

        <v-card-text class="white--text pt-0">
          Profi-Time - сервис онлайн-записи и аналитики
        </v-card-text>

        <v-divider></v-divider>

        <v-card-text class="white--text">
          {{ new Date().getFullYear() }} — <strong>Profi-Time.com</strong>
        </v-card-text>
      </v-card>
    </v-footer>
  </v-container>
</template>

<script>

export default {
  name: 'LeadPage',

  data: () => ({
    link: 'https://lk.profi-time.com/',
    register_link: 'https://lk.profi-time.com/register',
    icons: [
      {
        iconpath: require('../assets/gmail.svg'),
        href: 'mailto:lexapin.cheb@gmail.com?subject=Feedback Profi Time&body=Message',
        description: 'Написать письмо'
      },
      {
        iconpath: require('../assets/telegram.svg'),
        href: 'https://t.me/crm_profitime',
        description: 'Телеграм канал'
      },
      {
        iconpath: require('../assets/vk.svg'),
        href: 'https://vk.com/club223611620',
        description: 'Группа VK'
      },
      {
        iconpath: require('../assets/logo.png'),
        href: 'https://docs.profi-time.com/',
        description: 'Руководство пользователя'
      },
    ],
    cards: [
      {
        color: '#26c6da',
        icon: 'mdi-cellphone-message',
        topic: 'Уведомления',
        description: 'Вы ничего не упустите: мы оповестим Вас обо всех предстоящих записях',
      },
      {
        color: '#952175',
        icon: 'mdi-account-multiple-plus-outline',
        topic: 'Команда',
        description: 'Работайте со своими сотрудниками в одном расписании совместно!',
      },
      {
        color: '#385F73',
        icon: 'mdi-poll',
        topic: 'Анализ',
        description: 'Сводные отчеты работы команды за любой промежуток времени',
      },
    ],
    information: [
      {
        color: 'cyan',
        topic: 'Электронный журнал',
        description: 'Расписание записей Вашей компании, ' +
            'в котором могут работать все Ваши сотрудники совместно.',
        link: process.env.BASE_URL+'images/schedule.png'
      },
      {
        color: 'red',
        topic: 'Учет смен сотрудников',
        description: 'Позволяет распределить рабочее время между сотрудниками. Каждый сотрудник может сам сформировать свой график работы,' +
            ' а Вы контролировать учет рабочего времени',
        link: process.env.BASE_URL+'images/shifts.png'
      },
      {
        color: 'orange',
        topic: 'Онлайн-запись',
        description: 'Клиенты самостоятельно могут записаться к Вам онлайн! Просто разместите ссылку онлайн-записи в Ваших социальных сетях',
        link: process.env.BASE_URL+'images/online.png'
      },
      {
        color: 'green',
        topic: 'Работа в команде',
        description: 'Каждый участник команды может видеть свое расписание, показатели эффективности, участвовать в раскрутке самого себя онлайн',
        link: process.env.BASE_URL+'images/comand.png'
      },
      {
        color: 'pink',
        topic: 'Анализ клиентов',
        description: 'Анализируйте своих клиентов! Изучайте как часто они приходят к Вам и на какие услуги записываются',
        link: process.env.BASE_URL+'images/analytics.png'
      },
      {
        color: 'green',
        topic: 'Экспорт данных',
        description: 'Не хватает инструментов для анализа - экспортируйте свои данные в формате EXCEL',
        link: process.env.BASE_URL+'images/excel.png'
      },
      {
        color: 'amber',
        topic: 'Смотрите вперед',
        description: 'Развивайтесь вместе с нами, чтобы достичь наилучшего успеха!',
        link: process.env.BASE_URL+'images/clients.png'
      },
      {
        color: 'orange',
        topic: 'И все это абсолютно бесплатно!',
        year: '2000',
      },
    ],

    prices: [
      {
        topic: 'Пробная',
        description: '0',
        footer: 'на 14 дней',
      },
      {
        topic: 'Квартал',
        description: '899',
        footer: 'за 3 месяца',
      },
      {
        topic: 'Полгода',
        description: '1649',
        footer: 'за 6 месяцев',
      },
      {
        topic: 'Год',
        description: '2999',
        footer: 'за 12 месяцев',
      },
    ],
    }),
}
</script>
<style>
/* CSS */
.button-85 {
  padding: 0.6em 2em;
  border: none;
  outline: none;
  color: rgb(255, 255, 255);
  background: #111;
  cursor: pointer;
  position: relative;
  z-index: 0;
  border-radius: 10px;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
}

.button-85:before {
  content: "";
  background: linear-gradient(
    45deg,
    #ff0000,
    #ff7300,
    #fffb00,
    #48ff00,
    #00ffd5,
    #002bff,
    #7a00ff,
    #ff00c8,
    #ff0000
  );
  position: absolute;
  top: -2px;
  left: -2px;
  background-size: 400%;
  z-index: -1;
  filter: blur(5px);
  -webkit-filter: blur(5px);
  width: calc(100% + 4px);
  height: calc(100% + 4px);
  animation: glowing-button-85 20s linear infinite;
  transition: opacity 0.3s ease-in-out;
  border-radius: 10px;
}

@keyframes glowing-button-85 {
  0% {
    background-position: 0 0;
  }
  50% {
    background-position: 400% 0;
  }
  100% {
    background-position: 0 0;
  }
}

.button-85:after {
  z-index: -1;
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  background: #222;
  left: 0;
  top: 0;
  border-radius: 10px;
}

/* CSS */
.button-33 {
  background-color: #952175;
  border-radius: 100px;
  box-shadow: rgba(209, 196, 233, .2) 0 -25px 18px -14px inset,rgba(209, 196, 233, .15) 0 1px 2px,rgba(209, 196, 233, .15) 0 2px 4px,rgba(209, 196, 233, .15) 0 4px 8px,rgba(209, 196, 233, .15) 0 8px 16px,rgba(209, 196, 233, .15) 0 16px 32px;
  color: white;
  cursor: pointer;
  display: inline-block;
  font-family: CerebriSans-Regular,-apple-system,system-ui,Roboto,sans-serif;
  padding: 7px 20px;
  text-align: center;
  text-decoration: none;
  transition: all 250ms;
  border: 0;
  font-size: 16px;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
}

.button-33:hover {
  box-shadow: rgba(149, 117, 205,.35) 0 -25px 18px -14px inset,rgba(149, 117, 205,.25) 0 1px 2px,rgba(149, 117, 205,.25) 0 2px 4px,rgba(149, 117, 205,.25) 0 4px 8px,rgba(149, 117, 205,.25) 0 8px 16px,rgba(149, 117, 205,.25) 0 16px 32px;
  transform: scale(1.05);
}

</style>